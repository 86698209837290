<template>
	<div class="pagination hidden sm:flex flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
		<div class="flex flex-row ml-auto">
			<p>Строк на странице:</p>
			<ul class="flex flex-row">
				<li @click="updateRow(5)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': params.pageSize == 5 }">5</li>
				<li @click="updateRow(10)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': params.pageSize == 10 }">10</li>
				<li @click="updateRow(15)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': params.pageSize == 15 }">15</li>
			</ul>
		</div>
		<div class="flex flex-row ml-24">
			<ul class="flex flex-row">
				<li class="flex ml-4 cursor-pointer arrow" @click="currentPage !== 0 ? navigation('params', currentPage - 1) : ''" />
				<li class="flex">
					<span>Стр</span>
					<b class="ml-2">{{ currentPage + 1 }}</b>
					<span class="ml-2">из</span>
					<b class="ml-2">{{ totalCount === 0 ? 1 : totalCount }}</b>
				</li>
				<li class="flex ml-2 cursor-pointer arrow" @click="currentPage + 1 < totalCount ? navigation('params', currentPage + 1) : ''" />
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Paginations',
	props: {
		page: Number,
		totalCount: Number,
		params: Object
	},
	data() {
		return {
			currentPage: 0
		};
	},
	watch: {
		'params.currentPage'(val) {
			if (val === 0) this.currentPage = val;
		}
	},
	methods: {
		getData(params, flag) {
			this.$emit('query', params, flag);
		},
		updateRow(rowsVisible) {
			this.params.pageSize = rowsVisible;
			this.getData(this.params, false);
		},
		navigation(name, page) {
			this.currentPage = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		}
	}
};
</script>

<style scoped>
.pagination {
	white-space: nowrap;
	background: #fff;
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}
li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}
</style>
