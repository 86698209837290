<template>
	<div class="w-full relative">
		<div class="flex justify-between items-center">
			<h1 class="text-xl font-extrabold sm:text-2xl">Перечень медицинских организаций текущего года</h1>
			<div class="flex items-center">
				<Button text="Экспорт в Excel" icon="exel" @click.native="upLoadExcel(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="table-respons">
			<table>
				<thead>
					<tr>
						<th rowspan="2" class="th-sort">
							<p class="cursor-pointer" @click="switchFilterVisibility(0)">Наименование МО</p>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:filterOpen="filterOpen"
								position="left"
								:id-filter="0"
								:param="params"
								@query="getData"
								:fields="fields"
								:item="fields[0]"
							/>
						</th>
						<th colspan="5" class="text-center">Количество заполненных анкет</th>
					</tr>
					<tr>
						<th>
							<p class="cursor-pointer" @click="switchFilterVisibility(1)">Общее количество</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="1" :param="params" @query="getData" :fields="fields" :item="fields[1]" />
						</th>
						<th>
							<p class="cursor-pointer" @click="switchFilterVisibility(2)">Амбулаторные</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="2" :param="params" @query="getData" :fields="fields" :item="fields[2]" />
						</th>
						<th>
							<p class="cursor-pointer" @click="switchFilterVisibility(3)">Стационарные</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="3" :param="params" @query="getData" :fields="fields" :item="fields[3]" />
						</th>
						<th>
							<p class="cursor-pointer" @click="switchFilterVisibility(4)">Санаторно-курортные</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="4" :param="params" @query="getData" :fields="fields" :item="fields[4]" />
						</th>
						<th>
							<p class="cursor-pointer" @click="switchFilterVisibility(5)">Психиатрические</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="5" :param="params" @query="getData" :fields="fields" :item="fields[5]" />
						</th>
					</tr>
				</thead>
				<tbody v-for="item in includedMO" :key="item.medicalorgid">
					<tr :class="{ 'bg-gray-30': item.medicalorgid === opened, 'cursor-pointer': item.hasbranch }" @click="openRow(item)">
						<td>
							<p :class="{ underline: item.hasbranch }">{{ item.name }}</p>
						</td>
						<td>
							<p>{{ item.allcount }}</p>
						</td>
						<td>
							<p>{{ item.ambulatorcount }}</p>
						</td>
						<td>
							<p>{{ item.stacionarcount }}</p>
						</td>
						<td>
							<p>{{ item.sanatoriacount }}</p>
						</td>
						<td>
							<p>{{ item.psychiatriccount }}</p>
						</td>
					</tr>
					<template v-if="item.medicalorgid === opened">
						<tr v-for="subItem in item.branches" :key="subItem.medicalorgid" :class="{ 'bg-gray-30': item.medicalorgid === opened }">
							<td>
								<p class="filial" v-if="subItem.isbranch">Филиал</p>
								<p class="filial" v-if="subItem.isclone">Вторичная МО</p>
								<p>{{ subItem.name }}</p>
							</td>
							<td>
								<p>{{ subItem.allcount }}</p>
							</td>
							<td>
								<p>{{ subItem.ambulatorcount }}</p>
							</td>
							<td>
								<p>{{ subItem.stacionarcount }}</p>
							</td>
							<td>
								<p>{{ subItem.sanatoriacount }}</p>
							</td>
							<td>
								<p>{{ subItem.psychiatriccount }}</p>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<center v-if="!loadingTable && includedMO.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :totalCount="totalCount" :params="params" @query="getData" />
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import localActions from '../store/actions';
import loadingComponent from '@/components/Loading.vue';

export default {
	name: 'TableProtocols',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination
	},
	data() {
		return {
			opened: null,
			rowsVisible: 5,
			totalCount: 0,
			openDelet: false,
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: [],
				pageSize: 5,
				currentPage: 0
			},
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Общее количество',
					fieldName: 'allcount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorcount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarcount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriacount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatriccount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			includedMO: [],
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...localActions,
		...methods,
		openRow(item) {
			if (item.hasbranch) {
				if (this.opened === item.medicalorgid) {
					this.opened = null;
				} else {
					this.opened = item.medicalorgid;
				}
			}
		},
		upLoadExcel(params) {
			this.loadToExcell = true;
			this.$http
				.post('MedicalOrgs/GetIncludedMOExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Перечень медицинских организаций текущего года.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.getIncludedMO(params).then((res) => {
				this.includedMO = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
		}
	},
	mounted() {
		let regionId = this.getRegionId();

		this.params.filters[0].value1 = regionId;

		this.getRegionYearData(regionId).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, true);
		});
	}
};
</script>

<style scoped>
.table-respons td,
.table-respons th {
	padding: 20px 30px;
	text-align: left;
	vertical-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 385px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons table {
	width: 100%;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	cursor: pointer;
}

.bold td {
	font-weight: 600;
}

.table-respons {
	position: relative;
	background: #fff;
	border-radius: 10px 10px 0 0;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.table-respons .filial {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #fff;
	display: block;
	width: 110px;
	line-height: 28px;
	margin-bottom: 10px;
	text-align: center;
	background: #a8b1ce;
	border-radius: 4px;
}

.table-respons .active td,
.table-respons .filial-row td {
	border-bottom: 1px solid rgba(168, 177, 206, 0.2);
	border-right: 1px solid rgba(168, 177, 206, 0.2);
}

th.text-center {
	text-align: center;
}

.active {
	background: #f2f5ff;
}

.active .th-sort {
	color: #3377ff;
}

.filial-row {
	background: #f2f5ff;
}
</style>
